import { Link } from 'gatsby'
import React from 'react'

const StyledLogo = ({ logo }) => {
  const imageSrc = logo?.url ? logo.url : ''
  return (
    <>
      {logo && <Link to="/" aria-current="site-logo" className="flex pr-8">
        <img className={`xs:w-24 md:w-32`} src={imageSrc} alt={logo.caption}/>
      </Link>}
    </>

  );
};
export default StyledLogo
